import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import landing1 from './landing-1.png';
import logo from './dot.png';
import logoSysmec from './logo-sysmec.png';
import imgControl from './img-control.png';
import './App.css';
import { faGlobe, faMapMarked, faOilCan, faPhone, faShapes, faToggleOn } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container-lg">
          <a className="navbar-brand" href="#inicio">
            <img src={logo} className="img-nav" alt="Logo Dot Control" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#inicio">Inicio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">Sobre Dot Control</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#features">Características</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contacto</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Landing section */}
      <section id="inicio" className="row container-lg mx-auto">
        <div className="col-md-6 d-none d-md-flex div-landing">
          <img src={landing1} className="img-landing" alt="acompañando a empresas" />
        </div>
        <div className="col-md-6 div-landing">
          <img src={landing1} className="img-landing d-block d-md-none" alt="acompañando a empresas" />
          <h1>Dot Control</h1>
          <h6>
            Innovación Tecnológica, automatizando máquinas a través de <strong>Internet de las Cosas</strong>
          </h6>
        </div>
      </section>
      {/* End Landing section */}
      <hr />
      {/* About section */}
      <section id="about" className="div-about container-lg mx-auto">
        <h3>Sobre Dot Control</h3>
        <p>
          Dot Control se presenta como <strong>tecnología de monitoreo y control a distancia</strong> de generadores eléctricos diésel, con la mínima intervención de electrónica interna del equipo. Permitirá <strong>alertar condiciones inseguras de operación</strong> para el generador, además de eliminar el encendido en terreno por parte del personal planta. 
        </p>
        <div className="row">
          <div className="col-md-6 my-5">
            <div className="row">
              <h4 className="col-12">
                Esta tecnología escalable permite:
              </h4>
              <div className="col-md-6 div-option">
                <FontAwesomeIcon icon={faShapes} size="3x" className="mb-3 text-muted" />
                <p className="p-description">Trabajar con distintos modelos de generadores</p>
              </div>
              <div className="col-md-6 div-option">
                <FontAwesomeIcon icon={faMapMarked} size="3x" className="mb-3 text-muted" />
                <p className="p-description">Obtener variables tales como el posicionamiento georreferenciado, nivel y temperatura de combustible.</p>
              </div>
              <div className="col-md-6 div-option">
                <FontAwesomeIcon icon={faOilCan} size="3x" className="mb-3 text-muted" />
                <p className="p-description">Recibir alertas de cambio de aceite y paradas no programadas</p>
              </div>
              <div className="col-md-6 div-option">
                <FontAwesomeIcon icon={faToggleOn} size="3x" className="mb-3 text-muted" />
                <p className="p-description">Controlar encendido/apagado del generador y cargas eléctricas</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-5">
            <h4>Con esta solución se busca:</h4>
            <ul>
              <li><strong>disminuir tiempo del personal planta</strong> en labores de operación en generadores,</li>
              <li><strong>disminuir riesgos de accidentes</strong> en turnos nocturnos,</li>
              <li><strong>conocer</strong> el estado mecánico del generador,</li>
              <li><strong>preveer</strong> fallas del equipo.</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End About section */}
      <hr />
      {/* Features section */}
      <section id="features" className="div-features container-lg mx-auto">
        <h3>Características de <strong>DotControl Generador</strong></h3>
        <div className="row">
          <div className="col-md-6 my-5">
            <img src={imgControl} className="w-75 mx-auto" alt="Logo Sysmec" />
          </div>
          <div className="col-md-6 my-5">
            <h4>El dispositivo de <strong>DotControl Generador</strong> cuenta con:</h4>
            <ul>
              <li>Monitoreo variables generador</li>
              <li>Control arranque generador</li>
              <li>Control encendido luces u otra carga (opcional)</li>
              <li>Alimentación 12VDC batería generador</li>
              <li>Consumo: 12VDC, Arranque 30A, Monitoreo 500mA</li>
              <li>Incluye Switch cortacorriente para carga petróleo</li>
              <li>Peso: 500gr</li>
              <li>Comunicación 3/4G</li>
              <li>Integrado en APP móvil DOTCONTROL GENERADOR de SYSMEC</li>
              <li>Incluye cables de conexión a chapa y panel de control de generador</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Features section */}
      {/* Footer section */}
      <footer id="contact" className="footer bg-warning pt-5 mt-5">
        <div className="container-lg row mx-auto">
          <div className="col-md-4 my-md-5">
            <img src={logoSysmec} className="img-footer" alt="Logo Sysmec" />
            <hr className="my-3 mx-0" />
            <p>Te asesoramos técnicamente en tu proyecto para la toma de desicionesinformada, y así, definir y desarrollar un proyecto de innovación que se adecue a tu necesidad.</p>
          </div>
          <div className="col-md-6 offset-md-2 my-md-5">
            <ul>
              <li><FontAwesomeIcon icon={faEnvelope} size="lg" className="text-white" /> <a href="mailto:contacto@sysmec.cl">contacto@sysmec.cl</a></li>
              <li><FontAwesomeIcon icon={faPhone} size="lg" className="text-white" /> <a href="tel:+56985811612">+56 9 8581 1612</a> / <a href="tel:+56976435111">+56 9 7643 5111</a></li>
              <li><FontAwesomeIcon icon={faGlobe} size="lg" className="text-white" /> <a href="https://ww2.sysmec.cl/" target="_blank" rel="noreferrer">www.sysmec.cl</a></li>
            </ul>
          </div>
        </div>
      </footer>
      {/* End Footer section */}

    </>
  );
}

export default App;
